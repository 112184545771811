<template>
	<div class="pui-form">
		<v-form @submit.prevent class="mb-4 pb-4" ref="form" v-model="valid" lazy-validation v-if="modelLoaded">
			<div row wrap class="pui-form-layout">
				<pui-field-set :title="$t('pmactions.title')">
					<v-layout wrap>
						<v-flex xs12 md4>
							<pui-text-field :label="$t('pmactions.name')" v-model="model.name" required toplabel maxlength="150"></pui-text-field>
						</v-flex>
						<v-flex xs12 md4>
							<pui-text-field
								v-model="model.actioncode"
								:label="$t('pmactions.actioncode')"
								maxlength="20"
								required
								toplabel
							></pui-text-field>
						</v-flex>
						<v-flex xs12 md4>
							<pui-select
								v-if="isSuperAdmin"
								attach="pmorganizationid"
								:label="this.$t('pmactions.pmorganization')"
								:placeholder="this.$t('pmactions.phorganization')"
								toplabel
								clearable
								required
								:disabled="formDisabled"
								v-model="model"
								modelName="vluppmorganization"
								:modelFormMapping="{ pmorganizationid: 'pmorganizationid' }"
								:itemsToSelect="organizationItemsToSelect"
								itemValue="pmorganizationid"
								itemText="name"
							></pui-select>
							<pui-text-field
								v-else
								:label="$t('pmactions.pmorganization')"
								toplabel
								disabled
								required
								v-model="organizationName"
							></pui-text-field>
						</v-flex>
						<v-flex xs12 md4>
							<pui-select
								attach="pmdatasourcetypeid"
								:label="this.$t('pmdatasourceinstance.pmdatasourcetype')"
								:placeholder="this.$t('pmdatasourceinstance.phdatasourcetype')"
								toplabel
								clearable
								required
								reactive
								:disabled="hasOrganization"
								v-model="model"
								modelName="vluppmdatasourcetype"
								:modelFormMapping="{ pmdatasourcetypeid: 'pmdatasourcetypeid' }"
								:itemsToSelect="datasourcetypeItemsToSelect"
								itemValue="pmdatasourcetypeid"
								itemText="name"
								:fixedFilter="datasourcetypeFilter"
							></pui-select>
						</v-flex>
						<v-flex xs12 md4>
							<v-select
								v-model="model.actiontype"
								class="mt-2 px-1"
								:label="$t('pmactions.actiontype')"
								required
								:hide-details="true"
								:items="actionTypes"
							></v-select>
						</v-flex>
						<v-flex xs12 md4>
							<v-select
								v-model="model.actionmethod"
								class="mt-2 px-1"
								:label="$t('pmactions.actionmethod')"
								required
								:hide-details="true"
								:items="actionMethods"
							></v-select>
						</v-flex>
						<v-flex xs12 md3>
							<v-select
								v-model="model.authtype"
								class="mt-2 px-1"
								:label="$t('pmactions.authtype')"
								required
								:hide-details="true"
								:items="authTypes"
							></v-select>
						</v-flex>
						<v-flex xs12 md3>
							<pui-text-field
								v-model="model.authcredentials"
								:label="$t('pmactions.authcredentials')"
								maxlength="150"
								required
								toplabel
							></pui-text-field>
						</v-flex>
						<v-flex xs12 md3>
							<pui-text-field
								v-model="model.actionurl"
								:label="$t('pmactions.actionurl')"
								maxlength="150"
								required
								toplabel
							></pui-text-field>
						</v-flex>
						<v-flex xs12 md3>
							<pui-checkbox
								:label="$t('pmactions.isexternal')"
								v-model="model.isexternal"
								:true-value="1"
								:false-value="0"
								class="mt-4"
							></pui-checkbox>
						</v-flex>
						<v-flex xs12 md6>
							<pui-field-set :title="$t('pmactions.headers')">
								<v-jsoneditor
									v-model="model.headers"
									:options="options"
									:plus="false"
									disabled
									height="250px"
									@error="onErrorQuery"
								></v-jsoneditor>
							</pui-field-set>
						</v-flex>
						<v-flex xs12 md6>
							<pui-field-set :title="$t('pmactions.configuration')">
								<v-jsoneditor
									v-model="model.configuration"
									:options="options"
									:plus="false"
									height="250px"
									@error="onErrorQuery"
								></v-jsoneditor>
							</pui-field-set>
						</v-flex>
						<v-flex xs12 md6>
							<pui-field-set :title="$t('pmactions.successcondition')">
								<v-jsoneditor
									v-model="model.successcondition"
									:options="options"
									:plus="false"
									height="250px"
									@error="onErrorQuery"
								></v-jsoneditor>
							</pui-field-set>
						</v-flex>
						<v-flex xs12 md6>
							<pui-field-set :title="$t('pmactions.requestbody')">
								<v-jsoneditor
									v-model="model.requestbody"
									:options="optionsBody"
									:plus="false"
									height="250px"
									@error="onErrorQuery"
								></v-jsoneditor>
							</pui-field-set>
						</v-flex>

						<v-flex xs12 md6>
							<pui-number-field v-model="model.timeout" :label="$t('pmactions.timeout')" integer required toplabel></pui-number-field>
						</v-flex>
						<v-flex xs12 md6>
							<pui-number-field
								v-model="model.retrycount"
								:label="$t('pmactions.retrycount')"
								integer
								required
								toplabel
							></pui-number-field>
						</v-flex>
					</v-layout>
					<v-layout wrap>
						<v-flex xs12>
							<pui-text-area v-model="model.description" :label="$t('pmactions.description')" maxlength="250" toplabel></pui-text-area>
						</v-flex>
					</v-layout>
					<v-layout wrap>
						<v-flex xs12 md4>
							<pui-checkbox
								:label="$t('pmactions.disabled')"
								v-model="model.disabled"
								:true-value="true"
								:false-value="false"
								style="margin-top: -1em !important"
							></pui-checkbox>
						</v-flex>
					</v-layout>
				</pui-field-set>
			</div>
			<!-- footer -->
			<pui-form-footer v-if="!isModalDialog">
				<pui-form-footer-btns
					:formDisabled="formDisabled"
					:saveDisabled="saving"
					:saveAndNew="saveAndNew"
					:saveAndUpdate="saveAndUpdate"
					:save="save"
					:back="back"
				></pui-form-footer-btns>
			</pui-form-footer>
		</v-form>
		<pui-form-loading v-else></pui-form-loading>
	</div>
</template>

<script>
import PuiFormMethodsMixin from 'pui9-mixins/PuiFormMethodsMixin';

import { isSuperAdmin } from '@/api/common';
import VJsoneditor from 'v-jsoneditor';
export default {
	name: 'PmActionsForm',
	mixins: [PuiFormMethodsMixin],
	components: {
		VJsoneditor
	},
	data() {
		return {
			modelName: 'pmactions',
			isSuperAdmin: false,
			datasourcetypeFilter: null,
			actionTypes: [
				{ text: this.$t('pmactions.actiontypes.manual'), value: 'MANUAL' },
				{ text: this.$t('pmactions.actiontypes.automatic'), value: 'AUTOMATIC' }
			],
			actionMethods: [
				{ text: this.$t('pmactions.actionmethods.GET'), value: 'GET' },
				{ text: this.$t('pmactions.actionmethods.POST'), value: 'POST' },
				{ text: this.$t('pmactions.actionmethods.PUT'), value: 'PUT' },
				{ text: this.$t('pmactions.actionmethods.DELETE'), value: 'DELETE' }
			],
			authTypes: [
				{ text: this.$t('pmactions.authtypes.none'), value: 'none' },
				{ text: this.$t('pmactions.authtypes.basic'), value: 'basic' },
				{ text: this.$t('pmactions.authtypes.token'), value: 'token' },
				{ text: this.$t('pmactions.authtypes.oauth2'), value: 'oauth2' }
			],
			options: {
				mode: 'code',
				enableSort: true,
				enableTransform: true
				/* onChangeText(json) {
					console.log('onChange query', json);
				} */
			},
			optionsBody: {
				mode: 'code',
				enableSort: true,
				enableTransform: true
			}
		};
	},
	computed: {
		datasourcetypeItemsToSelect() {
			return [{ pmdatasourcetypeid: this.model.pmdatasourcetypeid }];
		},
		organizationItemsToSelect() {
			return [{ pmorganizationid: this.model.pmorganizationid }];
		},
		organizationName() {
			return this.userProperties['organizationname'];
		},
		isUpdateEntry() {
			return typeof this.model == 'object' && !!this.model.pmactionsid;
		},
		hasOrganization() {
			return !this.model.pmorganizationid;
		}
	},
	watch: {
		'model.actionmethod'(val) {
			console.log('action method', val);
			if (this.model && val === 'GET') {
				this.model.requestbody = null;
				this.optionsBody.onEditable = () => false;
			} else {
				this.optionsBody.onEditable = () => true;
			}
		}
	},
	beforeCreate() {
		if (this.model) {
			this.modelLoaded = true;

			this.model.pmorganizationid = this.userProperties['organizationid'];
		}
	},
	mounted() {
		this.isSuperAdmin = isSuperAdmin(this.session.profiles[0]);
		if (!this.isSuperAdmin) {
			this.datasourcetypeFilter = {
				groups: [],
				groupOp: 'and',
				rules: [{ field: 'pmorganizationid', op: 'eq', data: this.model.pmorganizationid || this.userProperties.organizationid }]
			};
		}
	},

	updated() {
		if (this.model) {
			if (!this.isSuperAdmin) {
				this.model.pmorganizationid = this.userProperties['organizationid'];
			}
		}
		console.log('this model', this.model);
	},
	methods: {
		onErrorQuery() {
			console.log('error query');
		}
	}
};
</script>
